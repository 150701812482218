<script setup>
import { useStorage } from '@vueuse/core'

const echo = useLaravelEcho()

const reports = useStorage('notificationReports', [])

echo.private(`employees.${user().id}`).listen('AddReportEvent', (e) => {
	const foundReport = reports.value.find((item) => item.id == e.report.id)

	if (foundReport) return

	reports.value.push({
		id: e.report.id,
		title: `${e.report.object.title} - ${e.report.work.category.title} - ${e.report.work.title}`,
		subtitle: e.report.date,
		to: `/objects/${e.report.object.id}#reports`,
	})
})
</script>

<template>
	<Notification
		icon="tabler-address-book"
		title="report.notifications"
		v-model="reports"
		@removeAll="reports = []" />
</template>
